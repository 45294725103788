import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import 'firebase/compat/app-check'
import { ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const prodConfig = {
  apiKey: "AIzaSyBAf5V8uSml4M4tWbBE0g1-lb1PbOTBWpg",
  authDomain: "monabrun-cff9c.firebaseapp.com",
  databaseURL: "https://monabrun-cff9c.firebaseio.com",
  projectId: "monabrun-cff9c",
  storageBucket: "monabrun-cff9c.appspot.com",
  messagingSenderId: "203001358317",
  appId: "1:203001358317:web:a72f51a84dc9acaed969e4",
  measurementId: "G-RLF6LJRJD3"
};

const stagingConfig = {
  apiKey: "AIzaSyACOL6crlKbZY8XBRwvumltTx_-ezGqcek",
  authDomain: "play-tours-staging.firebaseapp.com",
  databaseURL: "https://play-tours-staging.firebaseio.com",
  projectId: "play-tours-staging",
  storageBucket: "play-tours-staging.appspot.com",
  messagingSenderId: "377904938287",
  appId: "1:377904938287:web:4f7e4849b8751ad775f5da"
};

let firebaseApp: firebase.app.App
if(window.location.href.includes('localhost') || window.location.href.includes('playtours.app') && !window.location.href.includes('staging')){
  firebaseApp = firebase.initializeApp(prodConfig)
} else {
  firebaseApp = firebase.initializeApp(prodConfig)
}

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
self.FIREBASE_APPCHECK_DEBUG_TOKEN = window.location.href.includes('localhost') ? true : undefined;
const appCheck = firebaseApp.appCheck();
appCheck.activate(
  new ReCaptchaEnterpriseProvider('6Ld8uCQqAAAAAPZ1zuSIZ5QnunZetd4j-zstIUXN'),
  true
);

export default firebaseApp
