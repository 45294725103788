import Vue from 'vue'
import Vuex from 'vuex'
import firebaseApp from '@/firebase/init'
import router from '@/router/index'

Vue.use(Vuex)

// const firebaseAuth = firebaseApp.auth();
const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')

export default new Vuex.Store({
  state: {
    appVersion: null,
    user: null,
    userName: null,
    teamCode: null,
    gameCode: null,
    isTeam: null,
    adventures: [],
    userLocation: null,
    userLocationWatchId: null,
    userLocationWatchPassiveId: null,
    userLocationWatchPassiveTimeout: null,
    userLocationExpiryTimestamp: null,
    userAllowsLocationDetection: null,
    allowLenientLocationPicker: false,
    adventureName: null,
    adventureId: null,
    adventureNameDateString: null,
    adventureTeam: null,
    adventureView: null,
    currentStage: null,
    currentChallengeIndex: null,
    currentSortedChallengesIndex: null,
    showTaskAlert: false,
    showInventoryAlert: false,
    showChatAlert: false,
    showPowerupAlert: false,
    showNextStageAlert: false,
    completedTutorial: false,
    photoViewerUrl: null,
    showPhotoViewer: false,
    lobbyTitle: null,
    lobbyLogo: null,
    scrollPositions: {},
    panellumLocation: {},
    pannellumView: { scenes: {} },
    openTaskFromMapIndex: null,
    uiMods: {},
    taskMapZoom: null,
    taskCategoryFilter: null,
    translatorReturnPath: null,
    latestAccessCode: null,
    stopChallengeRouter: false,
    refreshPage: false
  },
  mutations: {
    updateAppVersion: (state, payload) => state.appVersion = payload,
    updateUser: (state, payload) => state.user = payload,
    updateUserName: (state, payload) => {
      localStorage.monabrunUserName = payload ? payload : ''
      state.userName = payload
    },
    updateTeamCode: (state, payload) => {
      localStorage.monabrunTeamCode = payload ? payload : ''
      state.teamCode = payload
    },
    updateGameCode: (state, payload) => {
      localStorage.monabrunGameCode = payload ? payload : ''
      state.gameCode = payload
    },
    updateIsTeam: (state, payload) => state.isTeam = payload,
    updateAdventureTeam: (state, payload) => {
      localStorage.monabrunAdventureName = payload ? payload.adventureName : null
      state.adventureTeam = payload
      state.adventureNameDateString = (new Date).toLocaleDateString()
      localStorage.monabrunAdventureNameDateString = state.adventureNameDateString
    },
    updateAdventureView: (state, payload) => {
      const views = payload.views.filter((el: any) => el.userName !== state.userName)
      const stats: any = {}
      views.map((el: any) => el.key).forEach((x: any) => { stats[x] = (stats[x] || 0) + 1; })
      state.adventureView = { views, stats }
    },
    updateAdventureName: (state, payload) => {
      localStorage.monabrunAdventureName = payload
      state.adventureName = payload
    },
    updateAdventureId: (state, payload) => {
      localStorage.monabrunAdventureId = payload
      state.adventureId = payload
    },
    updateCurrentStage: (state, payload) => state.currentStage = payload,
    updateCurrentChallengeIndex: (state, payload) => state.currentChallengeIndex = payload,
    updateCurrentSortedChallengesIndex: (state, payload) => state.currentSortedChallengesIndex = payload,
    updateUserLocation: (state, payload) => state.userLocation = payload,
    updateShowTaskAlert: (state, payload) => state.showTaskAlert = payload,
    updateShowInventoryAlert: (state, payload) => state.showInventoryAlert = payload,
    updateShowChatAlert: (state, payload) => state.showChatAlert = payload,
    updateShowPowerupAlert: (state, payload) => state.showPowerupAlert = payload,
    updateShowNextStageAlert: (state, payload) => state.showNextStageAlert = payload,
    updateCompletedTutorial: (state, payload) => {
      localStorage.monabrunCompletedTutorial = payload
      state.completedTutorial = payload
    },
    updateLobbyTitleAndLogo: (state, payload) => {
      state.lobbyTitle = payload ? payload.title : null
      state.lobbyLogo = payload ? payload.logo : null
    },
    showPhotoViewer: (state, payload) => {
      state.photoViewerUrl = payload
      state.showPhotoViewer = true
    },
    hidePhotoViewer: (state) => {
      state.photoViewerUrl = null
      state.showPhotoViewer = false
    },
    changeCurrentStage: (state, payload) => state.currentStage = state.currentStage + payload,
    setCurrentStage: (state, payload) => state.currentStage = payload,
    changeUserLocation: (state, payload) => {
      state.userLocation = {
        lat: payload.lat,
        lon: payload.lon
      }
    },
    getAdventuresMutation: (state, payload) => {
      masterFunction({
        methodName: 'get-adventures',
        ...payload
      })
      .then(result => { state.adventures = result.data })
      .catch(err => { console.log(err) })
    },
    getUserLocationMutation: (state, payload) => {
      state.userAllowsLocationDetection = payload.userAllowsLocationDetection
      if(payload.lat && payload.lon){
        state.userLocation = {
          lat: payload.lat,
          lon: payload.lon
        }
      }
      if(payload.allowLenientLocationPicker){
        state.allowLenientLocationPicker = payload.allowLenientLocationPicker
      }
      localStorage.setItem('playtoursUserLocation', `${payload.lat},${payload.lon}`)
      const now = new Date()
      state.userLocationExpiryTimestamp = new Date(now.getTime() + 0.5*60000) // 0.5 minute from now
    },
    updatePanellumLocation: (state, payload) => {
      const panellumLocation = JSON.parse(JSON.stringify(state.panellumLocation))
      panellumLocation[payload.stage] = payload.sceneName
      state.panellumLocation = panellumLocation
    },
    updatePannelumView: (state, payload) => {
      const pannellumView: any = state.pannellumView
      pannellumView.scenes[payload.title] = {
        stage: state.currentStage,
        pitch: payload.pitch,
        yaw: payload.yaw
      }
      pannellumView.latestSceneTitle = payload.title
      pannellumView.latestStage = state.currentStage
      state.pannellumView = pannellumView
    },
    // @ts-ignore
    updateScrollPositions: (state, payload) => state.scrollPositions[payload.pageName] = payload.scrollPosition,
    updateUiMods: (state, payload) => state.uiMods = payload,
    updateTaskMapZoom: (state, payload) => state.taskMapZoom = payload,
    updateTaskCategoryFilter: (state, payload) => {
      state.taskCategoryFilter = payload
      if(payload){
        localStorage.setItem('playToursCategoryFilter', payload)
      } else {
        localStorage.removeItem('playToursCategoryFilter')
      }
    },
    updateTranslatorReturnPath: (state, payload) => state.translatorReturnPath = payload,
    updateLatestAccessCode: (state, payload) => state.latestAccessCode = payload,
    updateStopChallengeRouter: (state, payload) => state.stopChallengeRouter = payload,
    updateRefreshPage: (state, payload) => state.refreshPage = payload,
    updateUserLocationWatchPassiveId: (state, payload) => state.userLocationWatchPassiveId = payload,
    updateUserLocationWatchId: (state, payload) => state.userLocationWatchId = payload,
    updateUserLocationWatchPassiveTimeout: (state, payload) => state.userLocationWatchPassiveTimeout = payload
  },
  actions: {
    getAdventuresAction: (context, payload) => {
      context.commit('getAdventuresMutation', payload)
    },
    updatePosition: (context, payload) => {
      masterFunction({
        methodName: 'update-position',
        teamCode: context.state.teamCode,
        position: {
          lat: payload.lat,
          lon: payload.lon
        }
      })
    },
    getUserLocationAction: (context, t) => {
      if(
        !context.state.userLocation &&
        context.state.userAllowsLocationDetection === false
      ){
        router.push({ name: 'LocationTutorial' })
      } else {
        if (!context.state.userLocation) {
          alert(t('You must click ALLOW if asked for location access. Else, you will not be able to proceed.'))
        }

        const options = {
          enableHighAccuracy: true,
          timeout: Infinity,
          maximumAge: 0
        }

        const runWatchPosition = (isPassive: boolean) => {
          return navigator.geolocation.watchPosition(pos => {
            if (isPassive && context.state.userLocationWatchPassiveTimeout) { return }
            else if (isPassive) {
              const throttleTimeout = setTimeout(() => {
                context.commit('updateUserLocationWatchPassiveTimeout', null)
              }, 90000)
              context.commit('updateUserLocationWatchPassiveTimeout', throttleTimeout)
            }

            context.commit('getUserLocationMutation', {
              userAllowsLocationDetection: true,
              lat: pos.coords.latitude,
              lon: pos.coords.longitude
            })
            context.dispatch('updatePosition', {
              lat: pos.coords.latitude,
              lon: pos.coords.longitude
            })
            navigator.geolocation.clearWatch(context.state.userLocationWatchId)
          }, err => {
            if(context.state.adventureTeam.allowLenientLocationPicker){
              router.push({ name: 'SelectLocation' })
            } else {
              context.commit('getUserLocationMutation', {
                userAllowsLocationDetection: false,
                lat: null,
                lon: null
              })
              router.push({ name: 'LocationTutorial' })
            }
          }, options)
        }

        context.commit('updateUserLocationWatchId', runWatchPosition(false))
        if (!context.state.userLocationWatchPassiveId) {
          context.commit('updateUserLocationWatchPassiveId', runWatchPosition(true))
        }
      }
    },
    enterPage: (context, payload) => {
      if(
        context.state.adventureTeam.teamMembers.length > 1 ||
        context.state.adventureTeam.stageSequenceModifier
      ) {
        masterFunction({
          methodName: 'enter-page',
          teamCode: context.state.teamCode,
          userName: context.state.userName,
          sessionId: context.state.adventureTeam.sessionId,
          pageDetails: payload
        })
      }
    },
    triggerAutoForward: (context, payload) => {
      masterFunction({
        methodName: 'auto-forward-to-next-stage',
        teamCode: context.state.teamCode,
        userName: context.state.userName,
        stage: payload.stage,
        endGame: !!payload.endGame
      }).then(() => {
        context.dispatch('updateShowNextStageAlert', true)
      })
    },
    openTaskFromMap: (context, payload) => {
      const challenge = context.state.adventureTeam.stageDetails.challenges[payload]
      let distance = null
      if ((context.state.userLocation.lat == challenge.position.lat) && (context.state.userLocation.lon == challenge.position.lon)) {
        distance = 0
      }
      else {
        const radlat1 = Math.PI * context.state.userLocation.lat/180
        const radlat2 = Math.PI * challenge.position.lat/180
        const theta = context.state.userLocation.lon - challenge.position.lon
        const radtheta = Math.PI * theta/180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        distance = dist*1000
      }
      if(
        challenge.position.radius_in_meters > distance ||
        context.state.userName.includes('TESTING')
      ) {
        router.push({
          name: 'Challenge',
          params: {
            stageIndex: context.state.currentStage,
            challengeIndex: payload+1
          }
        }
      )
      }
    },
    detectIncognito: (context, payload) => {
      const visitorId = payload
      masterFunction({
        methodName: 'check-incognito',
        visitorId
      }).then(result => {
        if(result.data.isIncognito){
          alert('WARNING: As you are using an incognito browser, the PlayTours game progress WILL NOT BE SAVED if you close and re-open the browser app. Please use a non-incognito browser instead.')
        }
      }).catch(err => {
          console.log(err)
      })
    }
  },
  modules: {
  }
})
