<template>
    <div>
        <div class="field">
            <label class="label">Email or Username</label>
            <div class="control has-icons-left">
                <input type="email" class="input" v-model="usernameOrEmail">
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
            </div>
            <p class="help">
                If no email is provided, you will be unable to reset your password.
            </p>
        </div>
        <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
                <input class="input" type="password" v-model="password">
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
            </div>
        </div>
        <div class="field">
        <p class="control">
            <button @click="register()" class="button is-success">
            Register
            </button>
        </p>
        </div>
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        <p v-if="successMessage" class="help is-success">{{ successMessage }}</p>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'Register',
    data() {
        return {
            usernameOrEmail: null,
            password: null,
            errorMessage: null,
            successMessage: null
        }
    },
    methods: {
        register() {
            this.errorMessage = null
            this.successMessage = null
            const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
            masterFunction({
                methodName: 'register',
                usernameOrEmail: this.usernameOrEmail,
                password: this.password
            }).then(result => {
                if(!result.data.uid){
                    this.errorMessage = result.data.error
                } else {
                    this.password = null
                    this.usernameOrEmail = null
                    this.successMessage = 'Successfully created your account!'
                }
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>
