const rules = [
    [/^[ \t\n]*$/gm, '<p class="py-2"></p>'],
    [/-{3,}/g, '<hr class="my-2"/>'],
    [/\*{3,}/g, '<hr class="my-2"/>'],
    [/_{3,}/g, '<hr class="my-2"/>'],
    [/#{7}\s?([^\n]+)/g, '<div class="is-size-7">$1</div>'],
    [/#{6}\s?([^\n]+)/g, '<div class="is-size-6">$1</div>'],
    [/#{5}\s?([^\n]+)/g, '<div class="is-size-5">$1</div>'],
    [/#{4}\s?([^\n]+)/g, '<div class="is-size-4 has-baskerville-font">$1</div>'],
    [/#{3}\s?([^\n]+)/g, '<div class="is-size-3 has-baskerville-font">$1</div>'],
    [/#{2}\s?([^\n]+)/g, '<div class="is-size-2 has-baskerville-font">$1</div>'],
    // [/!\[([^\[]+)\]\(([^\)]+)\)/g, '<div><a href=\'/photo-viewer/?url=$2\'><img style="display: block;margin-left: auto;margin-right: auto;" src=\'$2\' alt=\'$1\'></a></div>'], // eslint-disable-line
    [/!\[([^\[]+)\]\(([^\)]+)\)/g, '<div class="pointer"><img data-url=\'$2\' style="display: block;margin-left: auto;margin-right: auto; max-height: 350px; border-radius: 10px;" src=\'$2\' alt=\'$1\'></div>'], // eslint-disable-line
    [/\[([^\[]+)\]\(([^\)]+)\)/g, '<a href=\'$2\' target="_blank">$1</a>'], // eslint-disable-line
    [/\>>([^\*\n]+)\<</g, '<div class="has-text-centered" style="display: block;margin-left: auto;margin-right: auto;">$1</i>'], // eslint-disable-line
    [/\*\*([^\*\n]+)\*\*/g, '<b>$1</b>'], // eslint-disable-line
    [/\*([^\*\n]+)\*/g, '<i>$1</i>'], // eslint-disable-line
    // [/__([^_]+)__/g, '<b>$1</b>'],
    // [/_([^_]+)_/g, '<i>$1</i>'],
    [/~~([^_]+)~~/g, '<del>$1</del>'],
    [/((\n\d\..+)+)/g, '<ol class="px-4">$1</ol>'],
    [/((\n\*.+)+)/g, '<ul>$1</ul>'],
    [/\n\d\.([^\n]+)/g, '<li>$1</li>'],
    [/\n\*([^\n]+)/g, '<li>$1</li>'],
    [/\{([^\]]+)\]\(([^\)]+)\)/g, '<a href="$2">$1</a>'], // eslint-disable-line
    [/YT\[(.+?)\]YT/g, '<iframe width="100%" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'],
    [/IF\[(.+?)\]IF/g, '<iframe allowvr="yes" allowfullscreen="yes" width="100%" height="600" src="$1"></iframe>'],
    [/PDF\[(.+?)\]PDF/g, '<iframe allowvr="yes" allowfullscreen="yes" width="100%" height="600" src="$1"></iframe>'],
    [/MP3\[(.+?)\]MP3/g, '<audio style="display: block;margin-left: auto;margin-right: auto;" controls="controls"><source src="$1" type="audio/mpeg"></audio>'],
    [/VIDEO\[(.+?)\]VIDEO/g, '<video style="display: block;margin-left: auto;margin-right: auto; border-radius: 10px; max-height: 70vh;" controls="controls" controlsList="nodownload"><source src="$1" type="video/mp4"></video>'],
    [/MAP\[(.+?)\]MAP/g, '<iframe width="100%" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=$1&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>'],
    [/([^\n]+\n)/g, '<p class="">$1</p>']
    // [/\n{1,}/g, '<p class="py-2"></p>'],
    // [/(.+)((\r?\n.+)*)/g, '<p class="py-2">$1</p>']
  ]

export default {
    bind(el) {
        let html = el.textContent.replace(/\\n/g,'\n')
        rules.forEach(([rule, template]) => {
            html = html.replace(rule, template)
        })
        el.innerHTML = html
    }
}
