<template>
  <div class="container">
    <Signin/>
    <Register/>
    <VerifyEmail/>
    <ResetPassword/>
    <ProfileSettings/>
  </div>
</template>

<script>
import Signin from '@/components/Signin'
import Register from '@/components/Register'
import VerifyEmail from '@/components/VerifyEmail'
import ResetPassword from '@/components/ResetPassword'
import ProfileSettings from '@/components/ProfileSettings'

export default {
  name: 'Home',
  components: {
    Signin,
    Register,
    VerifyEmail,
    ResetPassword,
    ProfileSettings
  }
}
</script>
