<template>
  <div id="app" class="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
    </div> -->
    <transition-page>
      <router-view/>
    </transition-page>
  </div>
</template>

<script>
import Vue from 'vue'
import TransitionPage from './components/TransitionPage.vue'
import InApp from 'detect-inapp'

export default Vue.extend({
  name: 'App',
  components: {
    TransitionPage
  },
  mounted (){
    const googTransCookie = ('; '+document.cookie).split(`; googtrans=`).pop().split(';')[0]
    if(googTransCookie && googTransCookie.length > 0 &&
    !window.location.href.includes('googtrans')
    ){
      const expires = new Date()
      expires.setTime(expires.getTime() + (1000 * 24 * 60 * 60 * 1000))
      document.cookie = 'googtrans' + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:00 UTC;' + ' Max-Age=0; path=/; domain=' + location.host
      // window.location.reload()
    }

    // force refresh after 1 day
    if(!localStorage.getItem('monabrunLastRefresh')){
      localStorage.setItem('monabrunLastRefresh', (new Date()).getTime())
    }
    if(
      (new Date()).getTime() - parseInt(localStorage.getItem('monabrunLastRefresh')) > 86400000
    ){
      localStorage.setItem('monabrunLastRefresh', (new Date()).getTime())
      var url = window.location.href
      const appender = 'upd=' + (new Date()).getTime()
      if (url.indexOf('?') > -1) {
        url += '&' + appender
      } else {
        url += '?' + appender
      }
      window.location.href = url
    }

    // const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)
    // if (inapp.isMobile && localStorage.playToursInAppAck !== 'true') {
    //   this.$router.push({
    //     name: 'InAppBrowserTutorial'
    //   })
    // }
  }
})
</script>

<style>
html{
  background-color: white;
}
</style>
