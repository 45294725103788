<template>
    <div>
        <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left">
                <input type="email" class="input" v-model="email">
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
            </div>
            <p class="help">You are not able to reset your password if your username is not an email.</p>
        </div>
        <div class="field">
        <p class="control">
            <button @click="sendResetEmail()" class="button is-success">
            Send reset password email
            </button>
        </p>
        </div>
        <p v-if="successMessage" class="help is-success">{{ successMessage }}</p>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: null,
            successMessage: null
        }
    },
    methods: {
        sendResetEmail() {
            this.$store.dispatch('sendResetPasswordEmailAction', this.email)
        }
    }
}
</script>