<template>
    <div>
        <button @click="verifyEmail()" class="button is-success">
        Verify Email
        </button>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'VerifyEmail',
    data() {
        return {
            message: null
        }
    },
    computed: {
        user(){
            return this.$store.state.user
        }
    },
    methods: {
        verifyEmail() {
            this.$store.dispatch('sendVerifyEmailAction')
        }
    }
}
</script>