<template>
    <div>
        <div class="field">
            <label class="label">Email or Username</label>
            <div class="control has-icons-left">
                <input type="email" class="input" v-model="usernameOrEmail">
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
            </div>
        </div>
        <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
                <input class="input" type="password" v-model="password">
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
            </div>
        </div>
        <div class="field">
            <p class="control">
                <button @click="signin()" class="button is-success">
                Sign In
                </button>
            </p>
        </div>
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        <p v-if="successMessage" class="help is-success">{{ successMessage }}</p>
                <div class="field">
            <p class="control">
                <button @click="signout()" class="button is-danger">
                Sign Out
                </button>
            </p>
        </div>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'Signin',
    data() {
        return {
            usernameOrEmail: null,
            password: null,
            errorMessage: null,
            successMessage: null
        }
    },
    methods: {
        validateEmail(str) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(str);
        },
        signin() {
            this.successMessage = null
            this.errorMessage = null
            let usernameToLogin = this.usernameOrEmail
            if(!this.validateEmail(this.usernameOrEmail)){
                usernameToLogin = this.usernameOrEmail + '@longrandomdomainabcde.com'
            }
            firebaseApp.auth().signInWithEmailAndPassword(
                usernameToLogin,
                this.password
            ).then(cred => {
                console.log(cred)
                this.successMessage = 'Successfully signed in!'
                this.usernameOrEmail = null
                this.password = null
            }).catch(err => {
                console.log(err)
                this.errorMessage = 'Invalid username/password combination.'
            })
        },
        signout() {
            firebaseApp.auth().signOut().then(() => {
                // this.$router.push({ name: 'Home' })
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>