<template>
    <div>
        <div class="container">
            <div class="field">
                <label class="label">New Password</label>
                <div class="control has-icons-left">
                    <input class="input" type="password" v-model="password1">
                    <span class="icon is-small is-left">
                        <i class="fas fa-lock"></i>
                    </span>
                </div>
            </div>
            <div class="field">
                <label class="label">New Password (again)</label>
                <div class="control has-icons-left">
                    <input class="input" type="password" v-model="password2">
                    <span class="icon is-small is-left">
                        <i class="fas fa-lock"></i>
                    </span>
                </div>
                <p v-if="!isPasswordsMatch" class="help is-danger">Please ensure that the passwords match.</p>
            </div>
            <div class="field">
                <p class="control">
                    <button @click="changePassword()" class="button is-success">
                    Change Password
                    </button>
                </p>
            </div>
            <p v-if="resetPasswordErrorMessage" class="help is-danger">{{ resetPasswordErrorMessage }}</p>
            <p v-if="resetPasswordSuccessMessage" class="help is-success">{{ resetPasswordSuccessMessage }}</p>
            </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileSettings',
    data() {
        return {
            password1: '',
            password2: '',
            resetPasswordSuccessMessage: null,
            resetPasswordErrorMessage: null
        }
    },
    computed: {
        user(){
            return this.$store.state.user
        },
        isPasswordsMatch(){
            return this.password1 === this.password2
        }
    },
    methods: {
        changePassword(){
            this.password1 = ''
            this.password2 = ''
            this.resetPasswordSuccessMessage = null
            this.resetPasswordErrorMessage = null
            if(this.password1 === this.password2){
                this.user.updatePassword(this.password1).then(() => {
                    this.resetPasswordSuccessMessage = 'Successfully changed password.'
                }).catch(error => {
                    console.log(error)
                    this.resetPasswordErrorMessage = error.message
                });
            }
        }
    }
}
</script>