import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
// @ts-ignore
import markdown from './directives/markdown'
// @ts-ignore
import onlyInt from './directives/onlyInt'
// @ts-ignore
import onlyNum from './directives/onlyNum'
import VueGtag from 'vue-gtag'
// @ts-ignore
import VuePannellum from 'vue-pannellum'
// @ts-ignore
import VueMixpanel from 'vue-mixpanel'
// @ts-ignore
import json from '../package.json'
import '@/mixins/generalMixin'

Vue.use(onlyInt)
Vue.use(onlyNum)
Vue.use(VueViewer)
Vue.directive('markdown', markdown)
Vue.component('VPannellum', VuePannellum)

Vue.use(VueGtag, {
  config: { id: 'G-QNRL989NMN' },
  appName: 'PlayTours Player',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(VueMixpanel, {
  token: '51cfd193fe7d11af3a2e9d262fb980da',
  config: {
    debug: Vue.config.devtools
  }
})

Vue.config.productionTip = false
require('@/assets/main.scss');

if(!window.location.href.includes('localhost') || window.location.href.includes('playtours.app') && !window.location.href.includes('staging')){
  console.log = function() { return true }
  console.warn = function() { return true }
  console.debug = function () { return true }
  console.info = function () { return true }
  console.error = function() { return true }
  console.time = function() { return true }
  console.timeEnd = function() { return true }
}

store.commit('updateUserName', localStorage.monabrunUserName ? localStorage.monabrunUserName : '')
store.commit('updateTeamCode', localStorage.monabrunTeamCode ? localStorage.monabrunTeamCode : '')
store.commit('updateGameCode', localStorage.monabrunGameCode ? localStorage.monabrunGameCode : '')
store.commit('updateAdventureName', localStorage.monabrunAdventureName ? localStorage.monabrunAdventureName : '')
store.commit('updateAdventureId', localStorage.monabrunAdventureId ? localStorage.monabrunAdventureId : '')
store.commit('updateCompletedTutorial', localStorage.monabrunCompletedTutorial ? (localStorage.monabrunCompletedTutorial === 'true') : '')
store.commit('updateTaskCategoryFilter', localStorage.playToursCategoryFilter ? localStorage.playToursCategoryFilter : null)
store.commit('updateAppVersion', json.version)
if(localStorage.playtoursUserLocation){
  store.commit('getUserLocationMutation', {
    // userAllowsLocationDetection: true,
    lat: Number(localStorage.playtoursUserLocation.split(',')[0]),
    lon: Number(localStorage.playtoursUserLocation.split(',')[1])
  })
}

let app: import("vue/types/vue").CombinedVueInstance<Vue, object, object, object, Record<never, any>> | null = null;
firebase.auth().onAuthStateChanged(user => {
  // console.log('onAuthStateChanged', user)
  store.commit('updateUser', user)
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
