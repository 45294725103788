// @ts-nocheck
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import store from '../store'
import { deleteCookie } from '../utils/utils'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/adventure2',
    name: 'Adventure',
    component: () => import('../views/Adventure.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/postman',
    name: 'Postman',
    component: () => import('../views/Postman.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/select-location',
    name: 'SelectLocation',
    component: () => import('../views/SelectLocation.vue')
  },
  {
    path: '/enable-location',
    name: 'LocationTutorial',
    component: () => import('../views/LocationTutorial.vue')
  },
  {
    path: '/in-app-browser',
    name: 'InAppBrowserTutorial',
    component: () => import('../views/InAppBrowserTutorial.vue')
  },
  {
    path: '/scan/join/:a/:b',
    name: 'QrCodeTutorial',
    component: () => import('../views/QrCodeTutorial.vue')
  },
  {
    path: '/qr/:gameCode/:accessCode',
    name: 'QrStart',
    component: () => import('../views/QrStart.vue')
  },
  {
    path: '/translation',
    name: 'Translation',
    component: () => import('../views/Translation.vue')
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/',
    component: () => import('../views/AdventureRoot.vue'),
    children: [
      {
        path: '/',
        name: 'Start',
        component: () => import('../views/Start.vue')
      },
      // {
      //   path: '/',
      //   name: 'Adventures',
      //   component: () => import('../views/Adventures.vue')
      // },
      {
        path: '/play/:adventureNameSlug/:adventureId',
        name: 'AdventureIntro',
        component: () => import('../views/AdventureIntro.vue'),
        meta: { transitionName: 'slide' }
      },
      {
        path: '/play/:adventureNameSlug/:adventureId/:sessionId',
        name: 'AdventureIntroWithSessionId',
        component: () => import('../views/AdventureIntro.vue'),
        meta: { transitionName: 'slide' }
      },
      {
        path: '/p/:sessionId/',
        name: 'AdventureIntroWithOnlySessionId',
        component: () => import('../views/AdventureIntro.vue'),
        meta: { transitionName: 'slide' }
      },
      {
        path: '/experience/:adventureNameSlug/:adventureId',
        name: 'ExperienceIntro',
        component: () => import('../views/AdventureIntro.vue'),
        meta: { transitionName: 'slide' }
      }
    ]
  },
  {
    path: '/create-adventure',
    name: 'CreateAdventure',
    component: () => import('../views/CreateAdventure.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lobby/:teamCode',
    name: 'Lobby',
    component: () => import('../views/Lobby.vue')
  },
  {
    path: '/lobby/:teamCode/:sessionId',
    name: 'LobbyWithSession',
    component: () => import('../views/Lobby.vue')
  },
  {
    path: '/cert',
    name: 'CompletionCert',
    component: () => import('../views/CompletionCert.vue')
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import('../views/Tutorial.vue')
  },
  {
    path: '/select-powerup',
    name: 'SelectPowerup',
    component: () => import('../views/SelectPowerup.vue')
  },
  {
    path: '/game',
    component: () => import('../views/Game.vue'),
    children: [
      {
        name: 'Stage',
        path: '',
        component: () => import('../views/Stage.vue')
      },
      {
        name: 'Challenge',
        path: 'task/:stageIndex/:challengeIndex',
        component: () => import('../views/Challenge.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Powerups',
        path: 'powerups',
        component: () => import('../views/Powerups.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Scavenge',
        path: 'search-scan',
        component: () => import('../views/Scavenge.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'MapView',
        path: 'map',
        component: () => import('../views/MapView.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Inventory',
        path: 'inventory',
        component: () => import('../views/Inventory.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Chat',
        path: 'chat',
        component: () => import('../views/Chat.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Leaderboard',
        path: 'leaderboard',
        component: () => import('../views/Leaderboard.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Gallery',
        path: 'gallery',
        component: () => import('../views/Gallery.vue'),
        // meta: { transitionName: 'slide' }
      },
      {

        name: 'InviteTeam',
        path: 'invite-team',
        component: () => import('../views/InviteTeam.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'FaciMessaging',
        path: 'faci-messaging',
        component: () => import('../views/FaciMessaging.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Exit',
        path: 'exit',
        component: () => import('../views/Exit.vue'),
        // meta: { transitionName: 'slide' }
      },
      {
        name: 'Debrief',
        path: 'debrief',
        component: () => import('../views/Debrief.vue'),
        // meta: { transitionName: 'slide' }
      }
    ]
  },
  {
    path: '/stress-tester',
    name: 'StressTester',
    component: () => import('../views/StressTester.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let routeName = to.name
    if (to.name === 'Challenge') {
      routeName += String(to.params.stageIndex) + String(to.params.challengeIndex - 1)
    }
    const top = store.state.scrollPositions[routeName] ? store.state.scrollPositions[routeName] : 0
    setTimeout(() => {
      window.scrollTo({ top, behavior: 'smooth' })
    }, 800)
  }
})

router.beforeEach((to, from, next) => {
  router._backRoute = from.path

  if(router._lastChangeTimestamp){
    const now = new Date()
    const diff = now.getTime() - router._lastChangeTimestamp.getTime()
    const sec_diff = diff / 1000
    if(
      sec_diff > 300 &&
      to.name !== 'Challenge'
    ){
      router._lastChangeTimestamp = new Date()
      store.commit('updateRefreshPage', true)
    }
  } else {
    router._lastChangeTimestamp = new Date()
  }

  if (['AdventureIntroWithOnlySessionId', 'Lobby'].includes(to.name)) {
    deleteCookie('googtrans')
  }

  // if(store.state.stopChallengeRouter){
  //   const confirmed = confirm("You have not submitted your answer, (1) click 'Cancel' (2) scroll down and (3) click 'Submit'. \n\nElse, to clear your answer, click 'Ok'.")
  //   if (!confirmed) {
  //     return
  //   } else {
  //     store.commit('updateStopChallengeRouter', false)
  //   }
  // }

  if(to.matched.some(rec => rec.meta.requiresAuth)){
    const user = firebase.auth().currentUser
    if(user){
      next()
    } else {
      next({ name: 'Home' })
    }
  } else {
    next()
  }

  if(to.fullPath.includes('/game') || to.fullPath.includes('/lobby')){
    if (store.state.uiMods && store.state.uiMods.defaultBackgroundColour) {
      document.querySelector('html').style.backgroundColor = store.state.uiMods.defaultBackgroundColour
    } else {
      // document.querySelector('html').style.backgroundColor = '#064343'
    }
  } else {
    document.querySelector('html').style.backgroundImage = null
    document.querySelector('html').style.backgroundColor = 'white'
  }
})

router.afterEach((to, from, next) => {
  if(store.state.refreshPage){
    store.commit('updateRefreshPage', false)
    location.reload()
  }
})

export default router
